<template>
    <div class="fullpage-container">
        <div class="fullpage-wp" v-fullpage="opts" >
            <div class="page-1 page">
                <div id="back" @click="getNumber"></div>
            </div>
            <div class="page-2 page">

            </div>
            <div class="page-3 page">

            </div>
        </div>
    </div>
</template>
<style>
    *{
        margin: 0;
        padding: 0;
    }
    #app{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        color:red;
    }
    .page-1{
        height: 1300px;
        background: url("../../assets/coupon.png");
        background-size:100% 100%;
        position: relative;
    }
    #back{
        margin:0 auto;
        margin-top:400px;
        height: 150px;
        width: 100%;
        position: absolute;
    }
    .page-2{
        height: 0px;
        background: pink;
    }
    .page-3{
        height: 0px;
        background: blueviolet;
    }
    .page-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

</style>

<script>
    import { listPhone } from "@/api/login";
    import { Dialog, Image } from 'vant';
    export default {
        components: {},
  data () {
    return {
        phone: null,
        token: this.$route.params.token,
        opts: {
            nowWeek: null,
            start: 0,
            dir: 'v',
            loop: false,
            duration: 500,
            stopPageScroll: true,
            beforeChange: function (prev, next) {
             },
            afterChange: function (prev, next) {
            }
            }
         }
    },
    created() {
        this.getList();
    },
    methods: {
        /** 查询用户列表 */
        getList() {
            listPhone(this.token).then(response => {
                const code = response.code;
                if(code == 665){
                    Dialog({ message: '手机号获取失败' });
                }
                this.phone = response.data;
            });
        },
        getNumber(){
            //获取当前时间
            let myDate = new Date();
            let wk = myDate.getDay();
            let yy = String(myDate.getFullYear());
            let mm = myDate.getMonth() + 1;
            let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate());
            let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours());
            let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes());
            let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds());
            let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            let week = weeks[wk];
            this.nowDate = yy + '-' + mm + '-' + dd;
            this.nowTime = hou + ':' + min + ':' + sec;
            this.nowWeek = week;
            console.log(this.phone);
            if (week == "星期一"){
                window.location.href = "http://ysf.qlfintech.cn/user1/"+this.phone;
            }else if (week == "星期二"){
                window.location.href = "http://ysf.qlfintech.cn/user2/"+this.phone;
            }else if(week == "星期三"){
                window.location.href = "http://ysf.qlfintech.cn/user3/"+this.phone;
            }else if(week == "星期四"){
                window.location.href = "http://ysf.qlfintech.cn/user4/"+this.phone;
            }else if(week == "星期五"){
                window.location.href = "http://ysf.qlfintech.cn/user5/"+this.phone;
            }else if(week == "星期六"){
                window.location.href = "http://ysf.qlfintech.cn/user6/"+this.phone;
            }else if(week == "星期日"){
                window.location.href = "http://ysf.qlfintech.cn/user7/"+this.phone;
            }

                }
    }

};
</script>